<template>
  <div class="row feed boxw mb-1" style="overflow-x: hidden;">
    <div class=" box pb-2">
      <table class="table width-full table-condensed">
        <tbody>
          <tr>
            <td class="text-right" style="width:50%">
              <img class="rounded-circle" v-lazy="'/users/' + Math.floor($route.params.id /1000) + '/' + $route.params.user + '/imgp.jpg'">
            </td>
            <td style="width:50%">
              <div class="m-t-50 h4 text-uppercase">{{ perfil.name }}</div>
              <span class="m-t-10 sub-info" >{{perfil.idade}} {{$t('anos')}}, {{ perfil.city }}, {{ perfil.country }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-4 pr text-center">
        <span class="pr">
          <i class="far fa-user-circle" style="font-size:40px">
            <i class="far fa-user-circle circlei2">
              <i class="far fa-user-circle circlei3">
              </i>
            </i>
          </i>
        </span>
        <span class="sub-info font-weight-bold _tcenter" >
          1928 {{$t('profile.seguidores')}}
        </span>
        <hr width="80%">
      </div>
      <div style="display:flex;justify-content: space-around;" v-if="user.username !== perfil.username">
        <button v-if="!perfil.is_followed" @click="FNFollow(1)" class="btn btn-follow"><i class="fas fa-user-plus"></i> {{$t('follow')}}</button>
        <button v-else @click="FNFollow(0)" class="btn btn-follow"><i class="fas fa-user-minus"></i> {{$t('nofollow')}}</button>
        <!--perfil.follow_request_pending-->
        <button @click="SET_MODAL_MSG(1)" class="btn btn-message">{{$t('mensagem')}}</button>
      </div>
      <div class="row galeria mt-4">
        <div class="col-6 galeria-item p-0" v-if="user.username === perfil.username">
          <router-link class="text-center button button11" style="height: 100%;line-height: 100%;font-size: 2rem;padding-top: 80px;" to="/post/create">{{$t('profile.criar_post')}}</router-link>
        </div>

        <div v-for="(dt, index) in posts" :key="index" class="col-6 galeria-item"><router-link :to="'/post/' + $route.params.id + '/' + $route.params.user + '/' + dt.id"><img v-lazy="'/' + dt.image"></router-link></div>
        <!--
        <div class="col-6 galeria-item"><img v-lazy="'https://dummyimage.com/300x150/000/fff.jpg'"><i class="fas fa-circle icon-status-green"></i></div>
        <div class="col-6 galeria-item"><img v-lazy="'https://dummyimage.com/300x150/000/fff.jpg'"><i class="fas fa-circle icon-status-green"></i></div>
        <div class="col-6 galeria-item"><img v-lazy="'https://dummyimage.com/300x150/000/fff.jpg'"><i class="fas fa-circle icon-status-green"></i></div>
        <div class="col-6 galeria-item"><img v-lazy="'https://dummyimage.com/300x150/000/fff.jpg'"><i class="fas fa-circle icon-status-yellow"></i></div>
        <div class="col-6 galeria-item"><img v-lazy="'https://dummyimage.com/300x150/000/fff.jpg'"></div>
        <div class="col-6 galeria-item"><img v-lazy="'https://dummyimage.com/300x150/000/fff.jpg'"><i class="fas fa-circle icon-status-green"></i></div>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { success, error } from '../resources/functions'
import { user, follow, unfollow, postsUser } from './../resources/fn'

export default {
  name: 'profile',
  data () {
    return {
      perfil: {},
      posts: {}
    }
  },
  beforeMount () {
    this.SET_LOAD(true)
    user(this.axios, this.$route.params.id, this.$route.params.user).then((res) => {
      this.perfil = res.data
      this.perfil.idade = ~~((Date.now() - new Date(res.data.birthdate)) / 31557600000)
      this.SET_LOAD()
    }, (res) => {
      this.SET_LOAD()
    })
    postsUser(this.axios, this.$route.params.id, this.$route.params.user).then((res) => {
      this.posts = res.data
      this.SET_LOAD()
    }, (res) => {
      this.SET_LOAD()
    })
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_MODAL_MSG'
    ]),
    FNFollow (seguir) {
      this.SET_LOAD(true)
      if (seguir) {
        follow(this.axios, this.$route.params.id, this.$route.params.user).then((res) => {
          this.perfil.follow_request_pending = res.data.pending
          this.perfil.is_followed = true
          if (this.perfil.follow_request_pending) {
            success(this, this.$t('profile.pedido_seguimento_enviado_sucesso'))
          } else {
            success(this, this.$t('profile.passou_a_seguir').replace('@user', this.perfil.name))
          }
          this.SET_LOAD()
        }, (res) => {
          error(this, this.$t('profile.nao_foi_possivel_enviar_pedido'))
          this.SET_LOAD()
        })
      } else {
        unfollow(this.axios, this.$route.params.id, this.$route.params.user).then((res) => {
          this.perfil.follow_request_pending = false
          this.perfil.is_followed = false
          success(this, this.$t('profile.deixou_de_seguir').replace('@user', this.perfil.name))
          this.SET_LOAD()
        }, (res) => {
          error(this, this.$t('profile.nao_foi_possivel_enviar_pedido'))
          this.SET_LOAD()
        })
      }
    }
  }
}
</script>
